import { number } from "@amcharts/amcharts4/core";

export const AppConfig = {
  
  footerName : getFooterName(),
  titleName : 'WSEA',
  backroundImagePath : 'assets/images/login-bg.jpg', //assets/images/eco_bg.png
  logoImageUrlPresentAboveLogin : 'assets/images/weiss-logo.png', //assets/images/eco_login_logo.png
  logoImageUrl : 'assets/images/logo-sm.png', //assets/images/ecotech_logo.png
  mailIdFormat : 'e.g. user@gmail.com',
  attendeesOfWhichComapany : 'WSEA',
  salesPersonOfWhichComapany : 'WSEA',
  documentOfWhichComapany : 'WSEA',
  businessOfWhichComapany : 'WSEA',
  opportunitiesForWhichCompany : 'WSEA',
  weissContactDetailsOfWhichCountry : 'WSEA',
  weissWithAbbreviatedCountry : 'WSEA',
  QuotationPdfGenerationHostPath:'https://sea-crm.weiss-world.com/api/',
 // QuotationPdfGenerationHostPath:'http://democrm.ecotechservices.com/api/',
  //QuotationPdfGenerationHostPath:'http://localhost:64591/',
  //QuotationPdfGenerationHostPath:'https://uat-sea.ecotechinfra.com/api/'
  };

  function getFooterName() {
    const currentYear = new Date().getFullYear();
    return `© ${currentYear}, WEISS ASIA AUTOMATION COMPONENTS PTE LTD`;
  }